import './home.scss';
import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-jhipster';

export const PrevArrow = props => {
  const { onClick } = props;
  return (
    <div className="arrow prev" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

export const NextArrow = props => {
  const { onClick } = props;
  return (
    <div className="arrow next" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

export const Home = () => {
  const logoSlider = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: 'linear'
  };

  const logos = [
    "../../content/images/brands/coca-cola.svg",
    "../../content/images/brands/fanta.svg",
    "../../content/images/brands/sprite.svg",
    "../../content/images/brands/heinz.svg",
    "../../content/images/brands/hellmanns.svg",
    "../../content/images/brands/frenchs.svg",
    "../../content/images/brands/jbs.svg",
    "../../content/images/brands/unilever.svg",
    "../../content/images/brands/cheer.png",
    "../../content/images/brands/primo.png"
  ];

  return (
    <div className="home">
      {/* Floating Starburst Popup */}
      <div className="floating-starburst">
        <div className="popup-content">
          <p>
            <strong><Translate contentKey="home.outsource-delivery-line-1">We now deliver with:</Translate></strong>
          </p>
          <div className="partner-logos">
            <a href="https://www.ubereats.com/au/store/yankee-food/XqZCnJbZRFy7exgb0rQc-g" target="_blank" rel="noopener noreferrer">
              <img src="../../content/images/uber-eats.svg" alt="Uber Eats" />
            </a>
            <a href="https://www.doordash.com/en-GB/store/yankee-food-enoggera-32215283" target="_blank" rel="noopener noreferrer">
              <img src="../../content/images/doordash.svg" alt="DoorDash" />
            </a>
          </div>
          <p>
            <Translate contentKey="home.outsource-delivery-line-2">Place your orders now!</Translate>
          </p>
        </div>
      </div>

      {/* First Row: Text about the Food Company */}
      <Row className="mt-4">
        {' '}
        {/* Applying margin-top style */}
        <Col md={6} className="text-first-column">
          {' '}
          {/* Applying increased font size style */}
          <p>
            <Translate contentKey="home.brand-1">We bring </Translate><span className="color-highlight"><Translate contentKey="home.brand-2">the authentic and delicious</Translate></span> <br /><Translate contentKey="home.brand-3"> taste of American cuisine in our</Translate><br />
            <span className="color-highlight"><Translate contentKey="home.brand-4">bites.</Translate></span>
          </p>
        </Col>
        <Col md={6}>
          <p>
            <br />
            <strong className="color-red"><Translate contentKey="home.client-name">Yankee Food Company</Translate></strong>
            <br />
            <br />
            <Translate contentKey="home.client-statement">
            At Yankee Food, our delicious American burgers, hotdogs, corndogs, ice creams, and milkshakes are crafted to satisfy your cravings. We’re all about bringing you an unforgettable taste experience, with flavors as bold and authentic as America itself!
            </Translate>
            <br />
            <br />
            <br />
            <Translate contentKey="home.client-services-statement">
            Discover our star bites:
            </Translate>
          </p>
        </Col>
      </Row>

      {/* Second Row: Hot Dog Category */}
      <Row>
        <Col>
          <div className="text-center">
            <img src="../../content/images/products/cat-hotdog.png" className="category-ribbon-image" alt="Hod Dog Category" />
          </div>
        </Col>
      </Row>

      {/* Third Row: "Hot Dogs Images and descriptions" */}
      <Row>
        {/* Second Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/101.png"
              alt="Kevin Bacon Dog"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-1">
                DiCaprio Dog
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-1">
                The traditional plain hot dog commonly found in LA: a classic American sausage in a soft split-top bun, topped with Heinz ketchup and French's mustard.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Third Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/102.png"
              alt="Madonna Dog"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-2">
                Kevin Bacon Dog
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-2">
                Introducing the 'Kevin Bacon' hot dog: a fun twist on the classic Chicago flavours! A juicy American sausage in a soft bun, topped with crispy fried onions, smoky bacon, Heinz ketchup, and French's mustard. A delicious combination of bold flavors that’s sure to make your taste buds sizzle!
              </Translate>
            </p>
          </div>
        </Col>

        {/* Fourth Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/103.png"
              alt="Madonna Dog"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-3">
                Madonna Dog
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-3">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>
      </Row>

      {/* Fourth Row: Burger Category */}
      <Row>
        <Col md={12}>
          <div className="text-center">
            <img src="../../content/images/products/cat-burger.png" className="category-ribbon-image" alt="Burger Category" />
          </div>
        </Col>
      </Row>

      {/* Fifth Row: "Burger Images and descriptions" */}
      <Row>
        {/* Fifth Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/301.png"
              alt="Clinton Burger"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-4">
                Clinton Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-4">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Sixth Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/302.png"
              alt="Trump Burger"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-5">
                Trump Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-5">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Seventh Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/303.png"
              alt="Obama Burger"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-6">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-6">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>
      </Row>

      {/* Sixth Row: Fries Category */}
      <Row>
        <Col>
          <div className="text-center">
            <img src="../../content/images/products/cat-fries.png" className="category-ribbon-image" alt="Fries Category"/>
          </div>
        </Col>
      </Row>

      {/* Seventh Row: "Fries Images and descriptions" */}
      <Row>
        {/* First Column: Image and Text */}
        <Col md={12} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/001.png"
              alt="Delicious Fries"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-7">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-7">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>
      </Row>

      {/* Eighth Row: Ice Cream Category */}
      <Row>
        <Col>
          <div className="text-center">
            <img src="../../content/images/products/cat-icecream.png" className="category-ribbon-image" alt="Ice Cream Category"/>
          </div>
        </Col>
      </Row>

      {/* Ninth Row: "Ice Cream Images and descriptions" */}
      <Row>
        {/* Eighth Column: Image and Text */}
        <Col md={12} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/501.png"
              alt="Real Fruit Ice cream"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-8">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-8">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>
      </Row>

      {/* Tenth Row: Soda Category */}
      <Row>
        <Col>
          <div className="text-center">
            <img src="../../content/images/products/cat-soda.png" className="category-ribbon-image" alt="Drinks Category"/>
          </div>
        </Col>
      </Row>

      {/* Eleventh Row: "Sodas Images and descriptions" */}
      <Row>
        {/* Ninth Column: Image and Text */}
        <Col md={12} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/602-sodas.png"
              alt="Sodas"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-9">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-9">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>
      </Row>

      {/* Twelve Row: Combo Category */}
      <Row>
        <Col>
          <div className="text-center">
            <img src="../../content/images/products/cat-combo.png" className="category-ribbon-image" alt="Combo Category"/>
          </div>
        </Col>
      </Row>

      {/* Thirteenth Row: "Combo Images and descriptions" */}
      <Row>
        {/* Tenth Column: Image and Text */}
        <Col md={6} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/102602001.png"
              alt="Hot Dog Combo"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-10">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-10">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Eleventh Column: Image and Text */}
        <Col md={6} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/301602001.png"
              alt="Burger Combo"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-11">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-11">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>
      </Row>

      {/* Fourteenth Row: Extra Category */}
      <Row>
        <Col>
          <div className="text-center">
            <img src="../../content/images/products/cat-extra.png" className="category-ribbon-image" alt="Extras Category"/>
          </div>
        </Col>
      </Row>

      {/* Fifteenth Row: "Extra Images and descriptions" */}
      <Row>
        {/* Twelve Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/901.png"
              alt="Cheese"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-12">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-12">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Thirteenth Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/902.png"
              alt="Bacon"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-13">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-13">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Fourteenth Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/903.png"
              alt="Egg"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-14">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-14">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Fifteenth Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/904.png"
              alt="Sausage"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-15">
                Extra Eggs
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-15">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>

        {/* Sixteenth Column: Image and Text */}
        <Col md={4} className="text-left">
          <div className="image-food-container">
            <img
              src="../../content/images/products/905.png"
              alt="Aioli"
              className="image-food"
            />
            <p className="text-center">
              <Translate contentKey="home.product-name-16">
                Obama Burger
              </Translate>
            </p>
            <p className="text-left">
              <Translate contentKey="home.product-description-16">
                Get ready for the 'Madonna Dog' – a hot dog that’s as bold and iconic as the queen of pop herself! A juicy sausage in a soft bun, topped with fiery chili mince and spicy jalapeños, delivering a flavor explosion that’s sure to make you dance. Just like Madonna’s influence on the music scene, this hot dog brings the heat and the attitude! Perfectly at home in Miami, where vibrant flavors and spice are always in the air, just like Madonna’s legendary energy.
              </Translate>
            </p>
          </div>
        </Col>
      </Row>

      {/* Third Row: Video of a Beautiful Woman */}
{/*
      <Row className="video-container position-relative">
        <Col className="video-wrapper">
          <video className="full-width-video" autoPlay loop muted>
            <source src="../../content/images/beauty.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="text-above-button">
            <Translate contentKey="home.over-picture-1">Have time </Translate><br /> <Translate contentKey="home.over-picture-2">for yourself!</Translate>
          </div>
          {/*
          <Button
            className="heart-button btn" // Adding 'btn' class to ensure it's applied directly to the button
          >
            Book here
          </Button>
        </Col>
      </Row>
*/}
      {/* Sixth Row: Carousel with Suppliers Product Logos */}
      <Row>
        <Col>
          <div className="carousel-text">
            <h3><Translate contentKey="home.best-products">Best Selection of Products</Translate></h3>
            <p><Translate contentKey="home.best-products-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fringilla tellus nec blandit fermentum.</Translate></p>
          </div>
        </Col>
      </Row>
      <Row>
        <Slider {...logoSlider}>
          {
            logos.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image} alt="logo" />
              </div>
            ))
          }
        </Slider>
      </Row>

      {/* Seventh Row: Social Media banner */}
      <Row className="mt-4" style={{ height: '298px', backgroundColor: '#002868', justifyContent: 'center', alignItems: 'center' }}>
        <div className="text-center">
          <p><b><Translate contentKey="home.social-media">FOLLOW US ON SOCIAL MEDIA</Translate></b></p>
          <div>
            <a href="https://www.facebook.com/YankeeStadiumFoods/" target="_blank" rel="noopener noreferrer" className="social-icon-link">
              <img src="../../content/images/facebook.png" alt="Facebook" className="social-icon" />
            </a>
            <a href="https://www.instagram.com/realyankeefood" target="_blank" rel="noopener noreferrer" className="social-icon-link">
              <img src="../../content/images/instagram.png" alt="Instagram" className="social-icon" />
            </a>
          </div>
        </div>
      </Row>

      {/* Eighth Row: Address banner */}
      <Row className="mt-4 justify-content-center">
        {/* First Column */}
        <Col md={4} className="text-left">
          <p><Translate contentKey="home.opening-hours-1">OPENING HOURS:</Translate></p>
          <p><Translate contentKey="home.opening-hours-2">MONDAY: 9 AM - 5 PM</Translate></p>
          <p><Translate contentKey="home.opening-hours-3">TUESDAY-FRIDAY: 8 AM - 7 PM</Translate></p>
          <p><Translate contentKey="home.opening-hours-4">SATURDAY: 8 AM - 5 PM</Translate></p>
        </Col>

        {/* Second Column */}
        <Col md={4} className="text-center">
          <img src="../../content/images/logo-bottom.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
        </Col>

        {/* Third Column */}
        <Col md={4} className="text-left">
          <p><Translate contentKey="home.contact-1">Phone: (123) 456-7890</Translate></p>
          <p><Translate contentKey="home.contact-2">Email: example@example.com</Translate></p>
          <p><Translate contentKey="home.contact-3">Rua Presidente Kennedy, 1562</Translate></p>
          <p><Translate contentKey="home.contact-4">Ampere, PR - Brasil</Translate></p>
          <p><Translate contentKey="home.contact-5">85640-000</Translate></p>
        </Col>
      </Row>
    </div>
  );
};
export default Home;
